import { useState } from "react"
import { UndergradNav } from "./UndergradNav"
import { UndergradClubActivity } from "./UndergradClubActivity"
import { UndergradProjectActivity } from "./UndergradProjectActivity"
export function UnderGradActivity() {
    const [showUndergradPanel, setUndergradPanel] = useState([true, false])
    const passedData = (d) => {
        setUndergradPanel(d)
    }

    const RenderContent = () => {
        if (showUndergradPanel[0])
            return <UndergradProjectActivity />
        else return <UndergradClubActivity />
    }

    const showUndergradPanelFunc = () => {
        if (showUndergradPanel[0])
            return null
    }
    return (<>
        <div className="full-container">
            <UndergradNav passedData={passedData} />
            {RenderContent()}
        </div>
    </>)
}