import { Title } from "./GlobalStyles"
import { MdSend } from "react-icons/md"
import conf from "../config"
import { useState } from "react"
import axios from "axios"
import { ContactMeRightPanel } from "./ContactMeRightPanel"


export function ContactMe() {

    const [formVal, setformVal] = useState({ name: "", sender: "", msg: "", subject: "" })
    const [showAlert, setAlertState] = useState(false)
    function SendData() {
        console.log(formVal)
        axios.post(conf.baseUrl + "/backend/sendemail.php", formVal)
            .then(function (res) {
                if (res.data.data==='successful') {
                    console.log("successfull email submission")
                    setAlertState(true)
                    setTimeout(() => {
                        setAlertState(false)

                    }, 3000)
                }
                else console.log(res.data)
            })
            .catch(function (err) {
                console.log(err);
            });

    }
    const onInput = (e) => {
        const { name, value } = e.target
        setformVal({ ...formVal, [name]: value })

    }
    const ShoworHideAlert = () => {
        if (showAlert) {
            return (
                <div className="alert alert-success" role="alert">
                    Message has been sent successfully !
                </div>
            )
        }
    }
    
    return (
        <>
            <div className="contact-cont my-100 mb-0">
                <div className="row justify-content-center align-items-start">

                    <div className="col-12 col-sm-10 col-md-8 g-0 d-flex justify-content-center align-items-center bg-white">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-7 px-1 px-lg-4">
                            {
                                ShoworHideAlert()
                            }
                            <Title className="mb-4">Contact Me</Title>
                            
                            <div className="input-container">
                                <input type="text" name="name" value={formVal.name} onChange={onInput} />
                                <label>Name</label>
                            </div>
                            <div className="input-container">
                                <input type="text" name="sender" value={formVal.sender} onChange={onInput} />
                                <label>Email</label>
                            </div>
                            <div className="input-container">
                                <input type="text" name="subject" value={formVal.subject} onChange={onInput} />
                                <label>Subject</label>
                            </div>
                            <h6 style={{ fontSize: "14px" }} className="text-muted">Message</h6>
                            <div className="input-container my-2">
                                <textarea className="w-100" type="text" rows="10" name="msg" value={formVal.msg} onChange={onInput} />
                            </div>
                            <div className="d-flex justify-content-center align-items-center my-4">
                                <button className="py-1 px-2 btn btn-danger" onClick={() => { SendData() }}><MdSend className="fs-5 mx-2" />Send</button>
                            </div>
                        </div>
                        <ContactMeRightPanel />

                    </div>
                </div>
            </div>
        </>
    )
}