import {MdStar} from "react-icons/md"
import {SkillCardElm} from "../components/GlobalStyles"

import styled from "styled-components"

const LeftMarker =styled.div`
margin-left:-10px;
border-left:8px solid #32054a;
width:5px;
margin-right:20px;
`
const RightMarker =styled.div`
margin-left:5px;
border-right:8px solid red;
width:5px;
height:32px;
margin-right:-12px;
`

export function SkillCard ({data}){

    let finalComps=[]
    const RenderComponent =(data)=>{
        for(let i=0;i<data.rating;i++){
            finalComps.push(<MdStar key={i} className="fs-6 mx-1 star-col"/>)
        }
        let tempArry=finalComps
        finalComps=[]
        finalComps.push( <div className="col-12 col-sm-10 col-md-6 col-lg-10">{tempArry}</div>)
        return finalComps
    }
    
    return(
        <>
            <div className="skcard col-10 row justify-content-center align-items-center my-4">
                <SkillCardElm className="row justify-content-center align-items-center">
                    <div className="col-12 col-sm-10 col-lg-6 d-flex"><LeftMarker/><h4 className="fs-6 star-title fw-bold my-2 text-dark">{data.title}</h4></div>
                    <div className="col-12 col-sm-10 col-lg-6 d-flex justify-content-between">{RenderComponent(data)}<RightMarker/></div>
                </SkillCardElm>
            </div> 
        </>
    )
}