import { MdEmail } from "react-icons/md"
import { BsSkype } from "react-icons/bs"

export function ContactMeRightPanel() {
    return (<>
        <div className="contact-panel-right">
            <div className="h-100 d-flex justify-content-center align-items-center">
                <div>
                    <div className="d-flex justify-content-center justify-content-md-start align-items-center my-2">
                        <MdEmail className="text-white fs-6 me-2" />
                        <div className="fs-6 fw-normal text-white me-2">Email:<span className="ms-2 fs-6">abrar_bd@yahoo.com</span></div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center my-2">
                        <BsSkype className="text-white fs-6 me-2" />
                        <div className="fs-6 fw-normal text-white me-2">Skype:<span className="ms-2 fs-6">live:.cid.a8d42c19e3a707fd</span></div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}