import {Title,Image } from "./GlobalStyles"
import styled from "styled-components"

export const P=styled.p`
font-family: 'Josefin Sans', sans-serif;
font-size:1.20rem;
font-weight:200;
text-align:justify;
line-height:1.9;
`



export function AboutMe(){
    return(
        
        <div className="my-50">
            <Title>About Me</Title>
            <div className="row justify-content-center align-items-center my-50">
               
                <div className="col-11 col-lg-5 d-flex justify-content-center align-items-center">
                    <Image className="img-fluid" src="/myimage.jpg"/>
                </div>
                <div className="col-11 col-lg-4 mx-50">
                    <P>Hi I am Md. Abrar Zahin. I am a programmer and currently working as a Research Consultant in  <a className="me-2" href="http://cegisbd.com/">Center for Environmental and Geographic Information Services (CEGIS)</a>
 since 2020 in their Database, ICT and System Management Division. I build web application or web api for using ASP .NET Core Framework. I have good experience
with MySQL, Microsoft SQL Server, MongoDB, Sqlite database. I also have good experience in React and Vue for building the frontend of the application.

I love travelling and capturing the moments of life from nature. If you want to know something from me please let me know. I will try my best to help you.
Please put down your enquiry in the message box below or send me email in abrar_bd@yahoo.com. Thank you.</P>
                </div>
            </div>
        </div>
        
     
    )
}