import conf from "../../config"
import styled from "styled-components"
import { SliderPanel } from "../SliderPanel"
import { useState,useEffect } from "react"
import {MdArrowBack} from "react-icons/md"
import axios from "axios"

const ProjTitle=styled.h1`
margin:40px 5px 5px 5px;
font-size:2.25rem;
color:black;
font-weight:900;
text-align:center;
`
const ProjBody=styled.h5`
margin:100px 5px 5px 5px;
font-size:1.25rem;
font-family: 'Josefin Sans', sans-serif;
line-height:1.75;
color:#626163;
font-weight:400;
text-align:justify;
`


export function WorkCardDetails({info,modalToggle}){
    const setInformation =(text)=>{
        text=text.split("<br/>")
        let arry=[]
        text.forEach(x=>{
            arry.push(<><br/><br/></>)
            arry.push(x)
        })
        return arry
    }
    const [urls,setUrls]=useState([])
    useEffect(() => {
        axios.get(conf.baseUrl+"/backend/photo/search.php?search="+info.type+"&col=type").then((res) => {
            let data=res.data.data
            console.log(data)
            for(let i=0;i<data.length;i++){
                if(data[i]["keyword"]==info.keyword)
                    setUrls(urls=>[...urls,conf.baseUrl+res.data.data[i].url])
            }
            console.log(urls)       
        });
    }, []);

    setInformation(info.txt)
    return(
        <>
        <div className="d-flex justify-content-center align-items-center my-5">
                <button className="btn fw-bold fs-6 px-4 py-2" onClick={()=>{modalToggle(false)}}><MdArrowBack className="fs-4 fw-bold"/> Go Back</button>
            </div>
        <div className="row justify-content-center align-items-center">
        <SliderPanel urls={urls}/>
            <div className="my-5">
                <ProjTitle>{info.title}</ProjTitle>
                <ProjBody>{setInformation(info.txt)}</ProjBody>
            </div>
            <div className="d-flex justify-content-end align-items-center my-5">
                <button className="btn btn-outline-danger fs-6 px-4 py-2" onClick={()=>{modalToggle(false)}}>Go Back</button>
            </div>
        </div>
        </>
    )
}