import { useState, useEffect } from "react";
import { WorkComponent } from "../components/Work/WorkComponent";
import { WorkCardDetails } from "./Work/WorkCardDetails";
import conf from "../config";
import axios from "axios";

export function PersonalActivity() {
    
    const [modalData, setModal] = useState({})
    const [showModal, setmodalDisplayState] = useState(false)
    const modalToggle =(data)=>{
        setmodalDisplayState(data)
    }

    const callbackFunc = (data) => {        
        setmodalDisplayState(true)
        setModal(data)
    }
    const [cards, setCard] = useState([])
    useEffect(() => {
        axios.get(conf.baseUrl+"/backend/work/search.php?search=personal&col=type").then((res) => {
            console.log(res.data)
            setCard(res.data.data);
        });
    }, []);



    let finalItems = []
    if(!showModal){
        cards.map((x) => {
            finalItems.push(
                <div className="row justify-content-around align-items-center my-50" key={x.id}>
                    <WorkComponent info={x} callbackFunc={callbackFunc} />
                </div>
            )
        })
    }
    else{
        finalItems.push(<WorkCardDetails info={modalData} modalToggle={modalToggle}/>
        )
    }
    

    return (
        <>
            <div className="row justify-content-center align-items-center">
                {finalItems}
            </div>
        </>
    )
}