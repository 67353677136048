import { ScrollContainer, IconDiv } from "../components/GlobalStyles";
import {MdGroups,MdAccountCircle,MdOutlineHomeWork} from "react-icons/md";
import {FaChalkboardTeacher} from "react-icons/fa";
import { useState } from "react";
import { SocialActivity } from "../components/SocialActivity";
import { PersonalActivity } from "../components/PersonalActivity";
import { WorkplaceActivity } from "../components/Work/WorkplaceActivity";
import { UnderGradActivity } from "../components/Undergrad/UnderGradActivity";

export function MyWorks(){
    const [state,setVisibility]= useState([true,false,false,false])
    const [showAllWorks,setWorkListVisibility]= useState(false)
    
    const ShowElement =()=>{
        if(state[0]==true)
            return <SocialActivity/>
        else if(state[1]==true)
            return <PersonalActivity/>
        else if(state[2]==true){
            return <WorkplaceActivity/>
        }
        
        else return <UnderGradActivity/>
    }
    return(

        <div className="row justify-content-center align-items-center">
            <div className="col-12 col-sm-10 col-md-6 col-lg-2">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <IconDiv className="px-2" onClick={()=>{setVisibility([true,false,false,false])}}><MdGroups className="me-2 fs-2"/>Social Activity</IconDiv>
                    <IconDiv className="px-2" onClick={()=>{setVisibility([false,true,false,false])}} ><MdAccountCircle className="me-2 fs-3"/>Personal</IconDiv>
                    <IconDiv className="px-2" onClick={()=>{setVisibility([false,false,true,false])}}><MdOutlineHomeWork className="me-2 fs-3"/>Workplace</IconDiv>
                    <IconDiv className="px-2" onClick={()=>{setVisibility([false,false,false,true])}}><FaChalkboardTeacher className="me-2 fs-3"/>My Undergrad</IconDiv>
                </div>
            </div>

            <div className="col-12 col-sm-10 col-md-6 col-lg-8">
                <ScrollContainer>
                    {ShowElement()}
                </ScrollContainer>
            </div>

        </div>
        
    )
}