import styled from "styled-components"

export const Title=styled.h1`
font-family: 'Abel', sans-serif;
font-size:2.25rem;
font-weight:400;
text-align:center;
color:${props=>props.theme.fourthCol};   
`
export const P=styled.p`
font-family: 'Abel', sans-serif;
font-size:1.35rem;
font-weight:200;
text-align:justify;
`

export const Image=styled.img`
    padding:50px;
    width:80%;
`

export const Card=styled.div`
background:white;
box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
padding:20px 40px;
cursor:pointer;
transition:all 0.5s ease-in;
&:hover{
    transform:scale(1.15);
    z-index:999999;
}
`
export const CardImage=styled.img`
    padding:50px;
    width:80%;
`
export const CardP =styled.p`
padding-top:20px;
padding-bottom:20px;
font-family: 'Quicksand', sans-serif;
font-size:1.15rem;
font-weight:600;
text-align:justify;
`

export const ScrollContainer =styled.div`
overflow-y:auto;
height:85vh;
max-height:700px;
::-webkit-scrollbar {display:none;}
`

export const IconDiv =styled.div`
width:170px;
margin:10px 0;
cursor:pointer;
padding:8px 5px;
&:hover{
    background:${props=>props.theme.fifthCol};
    color:white;
}
`

export const Photo=styled.img`
height:250px;
object-fit:cover;
transition:all 0.6s ease-in;
z-index:99;
opacity:1;
&:hover{
    transform:scale(1.15);
    opacity:0.7;
}
`

export const SkillCardElm=styled.div`
background:white;
border-radius:10px;
box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
border-bottom:4px solid #32054a;
padding:5px;
cursor:pointer;
transition:all 0.5s ease-in;
&:hover{
    background:#e3e3e3;
    color:white; 
    transform:scale(1.15);
    z-index:999999;
}
`

export const  TitleLeftDesign= styled.div`
background:red;
height:100%;
padding:10px 25px;
margin-right:10px;
`

export const WorkText =styled.p`
padding:10px 15px;
font-family: 'Quicksand', sans-serif;
font-weight:500;
text-align:justify;
font-size:1.15rem;
`

export const LearnMore =styled.p`
font-size:0.85rem;
color:red;
font-weight:600;
transition:all 0.5s ease-in;
padding:2px 20px;
border-radius:20px;
&:hover{
    font-weight:400;
    color:white;
    background:red;
}
`