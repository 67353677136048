
export function SliderPanel({urls}) {   
    let tempElement=[],finalElement=[],i=0;
    //===check if urls is defined and have some elements
    if(urls !== undefined || urls.length>0){
        urls.forEach(url=>{
            let dynClass= i===0?"carousel-item active":"carousel-item"
            tempElement.push(
            <div className={dynClass} data-bs-interval="5000">
                <img src={url} className="d-block w-100" alt="..."/>
            </div>
            )
            i++
        })
        finalElement.push( 
            <div id="carouselExampleControls" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                        {tempElement}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
                </div>
        )

    }    
    
    
    return (
       
        <div className="row">
            {finalElement}
            {/* <div id="carouselExampleControls" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="5000">
                        <img src={conf.baseUrl+"/pictures/3.jpg"} className="d-block w-100" alt="..."/>
                    </div>
                    <div className="carousel-item" data-bs-interval="6000">
                        <img src={conf.baseUrl+"/pictures/11.jpg"} className="d-block w-100" alt="..."/>
                    </div>
                    <div className="carousel-item" data-bs-interval="5000">
                        <img src={conf.baseUrl+"/pictures/18.jpg"} className="d-block w-100" alt="..."/>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div> */}
        </div>
    )
}