import {TitleLeftDesign,LearnMore} from "../GlobalStyles"
import conf from "../../config"
export function WorkCard({info,callbackFunc}){
    let short_description = info.txt.split(' ').slice(0,20).join(' ');
    short_description+=" ........"
        
    return(
        <>
            <img className="img-fluid minh-img w-100" src={conf.baseUrl + info.imageUrl} alt="" />
            <div className=""></div>
            <div className="d-flex justify-content-start align-items-center">
                <TitleLeftDesign/>
                <h4 className="fs-6 fw-bold my-4">{info.title}</h4>
            </div>
            <div className="d-flex justify-content-end align-items-center">
                <LearnMore onClick={()=>{callbackFunc(info)}}>Learn More</LearnMore>
            </div>
                
        </>
    )
}