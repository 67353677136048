import { Photos } from "./Photos"
import { Title } from "../GlobalStyles"
export function Photography(){
    return(<>
        <div className="my-50">
            <Title className="my-50">My Photography</Title>
            <Photos/>
        </div>
        
    </>)
}