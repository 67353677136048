//import { useState } from "react"
import styled from "styled-components"

const NavTab=styled.h6`
padding:7px 20px;
transition:all 0.5s ease;
&:hover{
    color:${props=>props.theme.secondCol};
    background:${props=>props.theme.firstCol};
}
`

export function UndergradNav({passedData}){
    //const [showUndergradPanel,setUndergradPanel]=useState([true,false])
    //UndergradNavStateFunc(showUndergradPanel)
    return(
    <>
    <div style={{marginTop:"70px"}} className="d-flex w-100 justify-content-center align-items-center">
        <div className="col-12 col-md-6 col-lg-3 me-4">
            <NavTab role="button" className="fs-6" onClick={()=>passedData([true,false])}>Undergraduate Project</NavTab>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
            <NavTab role="button" className="fs-6" onClick={()=>passedData([false,true])}>Extracurricular Activities</NavTab>
        </div>
    </div>
    </>)
}