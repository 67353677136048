import { Photo } from "../GlobalStyles"
import conf from "../../config"
const RenderContent =()=>{
    let images=[
        "/pictures/1.jpg",
        "/pictures/2.jpg",
        "/pictures/3.jpg",
        "/pictures/4.jpg",
        "/pictures/5.jpg",
        "/pictures/7.jpg",
        "/pictures/8.jpg",
        "/pictures/9.jpg",
        "/pictures/10.jpg",
        "/pictures/11.jpg",
        "/pictures/12.jpg",
        "/pictures/13.jpg",
        "/pictures/20.jpg",
        "/pictures/21.jpg",
        "/pictures/22.jpg"
    ]

    let tempImg=[]
    let finalComponent=[]
    let returnedComponents=[]
    let i=1
    while(images.length !==0){
        tempImg=images.splice(0,3)
        returnedComponents=[]
        
        tempImg.forEach(img =>{
            returnedComponents.push(
                <Photo className="col-11 col-sm-10 col-md-6 col-lg-3 my-3 my-lg-0" key={i} src={conf.baseUrl+img}/>
            )
            i++                
        })

        finalComponent.push(<div className="row justify-content-center align-items-center my-50" key={i}>{returnedComponents}</div>)
        i++
    }
    return(finalComponent)
}

export function Photos(){
    let finalComp=RenderContent()
    return(finalComp)
}