import { Card,CardP } from "../GlobalStyles"
import {WorkCard} from "./WorkCard"
export function WorkComponent({info,callbackFunc}){
    
    const RenderElement =()=>{
        if(info.imageUrl !== undefined){
            return(
            <>
            <WorkCard info={info} callbackFunc={callbackFunc}/>
            </>)
        }
        else return(<CardP>{info.txt}</CardP>)
    }
    
    return(
        
        <>
        <div className="col-11 col-md-8 col-lg-7">
            <Card>
                {RenderElement()}
            </Card>
        </div>
        </>
    )
}