import './App.css'
import { Navbar } from './components/Navbar'
import { Home } from './pages/Home'
import {Routes,Route} from 'react-router-dom'
import { MyWorks } from './pages/MyWorks'
import { MySkills } from './pages/MySkills'
import {Footer} from './components/Footer'
function App() {
  
  return (
    <div>
      <Navbar/>
      <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/myworks" element={<MyWorks/>}/>
          <Route path="/myskills" element={<MySkills/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
