import { useState } from "react";
import { WorkComponent } from "../components/Work/WorkComponent";
import { ScrollContainer} from "./GlobalStyles";

export function SocialActivity(){
    const [cards] =useState([
        
    ])
    
    let finalItems=[]
    if(cards.length==0){
        finalItems.push(<><ScrollContainer className="col-11 col-md-8 col-lg-6 d-flex justify-content-center align-items-center">
                <h4 className="text-center fs-2">No Content Available</h4>
        </ScrollContainer></>)
    }
    else{
        cards.map((x,index)=>{
            finalItems.push(
                <div className="row justify-content-around align-items-center my-50" key={index}>
                    <WorkComponent info={x}/>
                </div>
            )
        })
    }
    
    

    return(
        <>
        <div className="row justify-content-center align-items-center">
            {finalItems}
        </div>
        </>
    )
}