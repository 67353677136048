import styled from "styled-components"
import { Link } from "react-router-dom"

//===styled components

const WebTitle =styled.h4`
font-size:2rem;
font-family: 'Cinzel', serif;
font-size:1.75rem;
font-weight:600;
padding-top:5px;
color:${props=>props.theme.secondCol};
&>a{
  text-decoration:none;
}
`
const AnchorElm =styled.li`
    background:white;
    color:${props=>props.theme.firstCol};
    font-size:1.5rem;
    padding:10px 25px;
    transition:all .4s ease-in;
    cursor:pointer;
    border-radius:10px;
    margin:20px 0;

&:hover{
    background:${props=>props.theme.secondCol};
    color:white;
}
`

export function Navbar() {
    return (
        <div className="navigation-container">
        <nav className="navbar navbar-expand-lg">
            <div className="w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <div className="col-12 col-md-10 col-lg-6 d-flex justify-content-between align-items-center">
                    <WebTitle><Link to="/">Abrar Zahin</Link></WebTitle>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                    <div>                        
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">                                
                                <li className="nav-item mx-lg-3 my-3 my-md-0"><Link to="/">Home</Link></li>
                                <li className="nav-item mx-lg-3 my-3 my-md-0"><Link to="/myskills">My Skills</Link></li>
                                <li className="nav-item mx-lg-3 my-3 my-md-0"><Link to="/myworks">My World</Link></li>
                            </ul>
                        </div>
                    </div>                    
            </div>
        </nav>
        </div>
    )
}