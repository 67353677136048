import { SliderPanel } from "../components/SliderPanel"
import { AboutMe } from "../components/AboutMe"
import { Photography } from "../components/Photo/Photography"
import { ContactMe } from "../components/ContactMe"
import conf from "../config"
export function Home(){    
    let urls=["/pictures/3.jpg","/pictures/11.jpg","/pictures/18.jpg"],finalUrls=[]
    urls.forEach(x=>{
        finalUrls.push(conf.baseUrl+x)
    })    

    return(
        <>
            <SliderPanel urls={finalUrls}/>
            <AboutMe/>
            <Photography/>
            <ContactMe/>
        </>        
    )
}