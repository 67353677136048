import { Title } from "../components/GlobalStyles";
import { SkillCard } from "../components/SkillCard";

export function MySkills() {
    const alldata = [
        {
            id: 1,
            title: "Vue JS",
            rating: 3
        },
        {
            id: 2,
            title: "React JS",
            rating: 5
        },
        {
            id: 3,
            title: "ASP .NET Core",
            rating: 5
        },
        {
            id: 4,
            title: "Django",
            rating: 5
        },
        {
            id: 5,
            title: "PHP",
            rating: 3
        },
        {
            id: 6,
            title: "MySQL",
            rating: 4
        },
        {
            id: 7,
            title: "Microsoft SQL Server",
            rating: 4
        },
        {
            id: 8,
            title: "MongoDB",
            rating: 4
        },
    ]
    let finalComps = []
    const RenderElm = (startFrom=0,End) => {
        finalComps=[]
        let data=alldata.slice(startFrom,End)
        data.map(d => {
            finalComps.push(<SkillCard key={d.id} data={d} />)
        })
        return finalComps
    }
    return (
        <>
            <Title>Skills and Experience</Title>
           <div className="skillheight row justify-content-center align-items-center">
                <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                    {RenderElm(0,4)}
                </div>
                <div className="col-12 col-sm-10 col-md-6 col-lg-4">
                    {RenderElm(4,alldata.length)}
                </div>
            </div>
        </>
    )
}