export function Footer() {
    return (
        <>
            <footer className="flex-shrink-0 py-4 bg-dark text-white-50">
                <div className="container text-center text-white">
                    <small>Copyright &copy; Abrar Zahin</small>
                </div>
            </footer>
        </>
    )
}